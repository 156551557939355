import React from 'react';
// import logo from './logo.svg';
import 'styles/css/styles.css';


import Layout from 'containers/Layout';

function App() {
  return (
    <Layout />
  );
}

export default App;